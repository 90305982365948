import { render, staticRenderFns } from "./buy.vue?vue&type=template&id=415b5964&scoped=true&lang=true"
import script from "./buy.vue?vue&type=script&lang=js"
export * from "./buy.vue?vue&type=script&lang=js"
import style0 from "./buy.vue?vue&type=style&index=0&id=415b5964&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "415b5964",
  null
  
)

export default component.exports